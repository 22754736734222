import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M1641 3769 c-14 -9 -48 -28 -76 -43 -27 -16 -79 -45 -115 -65 l-65
-36 149 -3 149 -3 -7 -212 c-4 -116 -14 -394 -22 -617 -7 -223 -14 -438 -14
-477 l0 -73 140 0 c137 0 140 0 144 23 4 20 18 508 32 1102 3 138 7 255 9 261
2 7 32 8 91 3 259 -22 399 -90 465 -225 30 -60 32 -71 31 -167 -2 -241 -90
-365 -356 -498 -76 -38 -135 -69 -130 -70 5 0 43 0 84 1 117 1 243 -25 339
-71 l85 -40 143 53 c78 29 141 58 140 63 -2 6 -27 27 -56 47 -79 55 -166 81
-296 87 -84 5 -101 8 -72 13 63 12 198 83 258 137 69 62 121 138 155 228 23
61 26 78 21 163 -3 73 -10 108 -30 151 -61 136 -190 225 -385 264 -120 25
-772 28 -811 4z"/>
<path d="M3050 2611 c-186 -41 -460 -145 -575 -219 -38 -25 -95 -69 -125 -98
l-55 -54 70 0 c68 0 71 1 97 34 65 82 208 169 340 207 219 63 605 11 616 -83
4 -33 2 -33 55 -7 88 45 174 126 163 154 -3 8 -20 22 -38 31 -68 35 -132 46
-298 50 -134 3 -181 0 -250 -15z"/>
<path d="M2843 2390 c-83 -22 -133 -45 -133 -63 0 -6 3 -29 6 -49 l7 -38 150
0 150 0 -5 73 c-3 41 -8 79 -12 85 -10 17 -80 14 -163 -8z"/>
<path d="M995 2150 c-19 -8 -8 -10 60 -10 3 0 5 3 5 8 1 11 -42 12 -65 2z"/>
<path d="M1128 2055 c-3 -94 -1 -105 14 -105 11 0 17 7 16 18 -1 9 0 34 0 55
l2 37 51 0 51 0 -4 -55 c-2 -46 0 -55 14 -55 18 0 33 16 20 22 -4 1 -5 45 -2
96 4 83 3 92 -12 92 -14 0 -18 -9 -18 -43 l0 -43 -50 0 -50 1 0 43 c0 30 -4
42 -14 42 -12 0 -15 -22 -18 -105z"/>
<path d="M1407 2153 c-43 -4 -46 -6 -37 -24 6 -10 9 -55 7 -99 l-4 -80 46 2
c37 1 39 2 14 5 -32 4 -33 6 -33 49 0 42 1 44 30 44 17 0 30 5 30 11 0 6 -12
9 -27 7 -26 -3 -28 0 -31 35 l-3 37 41 0 c25 0 39 4 35 10 -3 6 -9 9 -13 8 -4
-1 -28 -3 -55 -5z"/>
<path d="M1755 2152 c-5 -4 -10 -51 -10 -105 0 -80 3 -97 15 -97 12 0 16 18
18 92 2 51 5 95 7 99 3 3 16 4 29 2 29 -6 41 -33 31 -71 -6 -24 -5 -25 8 -12
45 46 17 100 -50 100 -21 0 -42 -4 -48 -8z"/>
<path d="M2035 2152 c-5 -4 -10 -51 -10 -105 0 -80 3 -97 15 -97 12 0 15 16
13 94 -1 84 0 95 17 99 10 3 28 -2 40 -10 18 -13 21 -22 16 -52 -6 -34 -5 -35
9 -17 21 28 19 73 -4 86 -23 12 -81 13 -96 2z"/>
<path d="M2900 2158 c-1 -2 -2 -49 -3 -106 -2 -85 0 -102 13 -102 12 0 16 20
18 105 3 90 1 105 -12 105 -9 0 -16 -1 -16 -2z"/>
<path d="M3008 2055 l-3 -105 45 0 c25 0 52 5 60 10 11 7 3 10 -30 10 l-45 0
5 95 c4 84 3 95 -12 95 -14 0 -17 -14 -20 -105z"/>
<path d="M3168 2055 l-3 -105 45 0 c25 0 52 5 60 11 11 7 3 9 -30 7 l-46 -3 3
60 c2 33 5 77 6 98 2 29 -1 37 -15 37 -14 0 -17 -14 -20 -105z"/>
<path d="M3380 2150 l-25 -8 28 -1 c15 -1 29 -1 32 -1 9 0 7 14 -2 16 -4 1
-19 -2 -33 -6z"/>
<path d="M1592 2128 c-6 -13 -21 -48 -32 -78 -12 -30 -28 -65 -36 -77 -13 -21
-13 -23 6 -23 12 0 18 4 15 10 -13 20 21 63 53 66 29 3 32 1 42 -36 6 -24 17
-40 25 -40 8 0 15 1 15 3 -4 28 -60 192 -67 194 -5 2 -15 -7 -21 -19z m27 -75
c1 -7 -8 -13 -19 -13 -23 0 -25 12 -8 43 11 21 11 21 19 2 4 -11 8 -26 8 -32z"/>
<path d="M2249 2103 c-30 -69 -50 -115 -61 -135 -7 -14 -5 -18 8 -18 10 0 15
3 11 6 -12 12 23 64 48 70 32 8 43 0 51 -41 4 -25 11 -35 25 -35 20 0 24 10
10 26 -4 5 -19 46 -31 92 -13 45 -28 82 -32 82 -5 0 -18 -21 -29 -47z m33 -38
c4 -21 1 -25 -18 -25 -28 0 -28 1 -13 34 14 30 25 26 31 -9z"/>
<path d="M2400 2145 c0 -3 16 -29 35 -59 32 -47 35 -58 29 -94 -6 -37 -4 -42
12 -42 16 0 19 7 16 48 -4 47 13 85 58 140 8 9 6 12 -10 12 -12 0 -20 -6 -18
-12 1 -7 -6 -28 -16 -47 l-18 -33 -24 35 c-13 20 -24 41 -24 46 0 6 -9 11 -20
11 -11 0 -20 -2 -20 -5z"/>
<path d="M2705 2143 c7 -5 8 -43 5 -101 l-5 -92 35 1 c20 1 26 4 14 6 -19 4
-20 9 -16 91 2 48 6 89 7 90 2 2 13 1 24 -3 26 -8 28 -52 4 -71 -16 -12 -16
-13 -2 -14 30 0 42 -26 30 -61 -11 -32 -11 -32 9 -14 24 22 26 70 4 83 -14 8
-14 12 -3 31 23 35 1 56 -62 59 -30 1 -49 -1 -44 -5z"/>
<path d="M934 2122 c-19 -12 -34 -49 -34 -82 0 -64 88 -114 140 -80 11 7 6 10
-21 10 -72 0 -112 60 -85 125 8 19 14 35 13 35 -1 0 -7 -4 -13 -8z"/>
<path d="M3332 2118 c-23 -23 -13 -48 33 -76 27 -16 45 -34 45 -45 0 -10 5
-15 10 -12 22 14 8 45 -32 72 -30 20 -43 36 -42 51 1 26 1 25 -14 10z"/>
<path d="M3314 1968 c-9 -15 1 -20 36 -16 l33 3 -32 12 c-20 7 -33 8 -37 1z"/>
<path d="M1626 1868 c-3 -7 -7 -50 -10 -95 -7 -108 -38 -200 -79 -235 l-31
-28 227 0 228 0 -3 68 c-3 67 -3 67 -33 70 -16 1 -54 5 -84 8 l-53 6 30 31
c33 34 65 100 77 155 l7 32 -137 0 c-100 0 -137 -3 -139 -12z"/>
<path d="M2097 1873 c-8 -15 -27 -109 -38 -193 -18 -144 6 -326 61 -446 132
-293 453 -484 810 -484 127 0 215 21 343 79 95 44 274 157 264 167 -1 2 -31
-5 -64 -16 -87 -27 -221 -49 -338 -56 -305 -18 -589 111 -731 333 -84 131
-114 257 -114 473 l0 150 -94 0 c-52 0 -97 -3 -99 -7z"/>
<path d="M2655 1872 c-6 -4 -27 -30 -48 -58 -51 -71 -114 -127 -170 -150 -39
-16 -47 -23 -47 -46 0 -24 2 -26 28 -18 56 17 156 60 211 91 94 53 231 160
231 181 0 10 -191 10 -205 0z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
